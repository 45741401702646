<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "zhongDi",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#默认背包快捷键是B',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到种地页",
                            "#按图1说明参数:",
                            "1.根据你要种的种子,选择施肥次数",
                            "2.可以设置种地的次数,完成次数或者体力不足自动停止,可以接下一步任务",
                            "3.种子或者肥料不足,停止任务,不会接下一步任务",
                            "4.定点种地是一套高效的种地办法,下面会说明,这里不勾选是正常种地",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#在农夫购买足够的种子,足够的肥料,流程是种一个催熟后拾取",
                            "#种子是从左上到右下,种优先识别到的种子",
                            '#角色移动到农田中间位置,开始任务',
                        ],
                        img:[
                        ]
                    },
                    {
                        title: "定点种地功能说明",
                        text:[
                            "1.在上面设置好的参数基础上,勾选定点种地",
                            "2.切换到游戏,在农田种一个种子(图1),然后移动到种子上,并且把视角切到图2所示,这样每次种的作物都会在箭头指的位置",
                            "3.每次种地之后,会扫描箭头指的位置的作物,然后施肥拾取,再种地,以此循环",
                            "4.如果想更快速的扫描到种子,可以设置作物在屏幕的坐标,以图3图4举例(截图工具用的是微信的截图),设置好后,每次扫描作物" +
                            "会以设置的坐标优先扫描",
                            "5.以上参数设置好,游戏角色位置视角卡好后,开始任务",
                        ],
                        img:[
                            "2.png",
                            "3.png",
                            "4.png",
                            "5.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
